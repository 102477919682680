import { Card, CardContent, Box, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ChevronRightOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme =>
    createStyles({
        partnerCard: {
            backgroundImage: "url('/imgs/partner-banner.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "380px",
            height: "470px",
            boxShadow: "none"
        }
    })
);

const PartnerCard = () => {
    const classes = useStyles();

    return (
        <Card className={classes.partnerCard}>
            <CardContent>
                <Box justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"} style={{ height: "100%" }} gridGap={32}>
                    <Typography style={{ fontWeight: 700, fontSize: 26, marginTop: 48, color: "#273340" }} align="center">
                        Erhalte <strong>20%</strong> Provision für
                        <br /> 24 Monate & verschenke <br /> 30 Tage onpreo! <br />
                        💸
                    </Typography>
                    <a
                        href="https://link.onpreo.com/de/affiliate"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ textDecoration: "underline", color: "#0071E3" }}
                    >
                        <Typography style={{ fontWeight: 600 }} align="center">
                            Werde onpreo Affiliate-Partner <ChevronRightOutlined style={{ width: 24, height: 24, marginBottom: 2 }} />
                        </Typography>
                    </a>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PartnerCard;
