import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { Link, LoadingButton, RelativeFooter } from "@onpreo/components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControlLabel, Checkbox, FilledInput, InputAdornment, createStyles, makeStyles, ButtonBase, Box } from "@material-ui/core";
import { signIn } from "next-auth/react";
import { Card, CardContent, Typography } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { Email, Visibility, VisibilityOff } from "@material-ui/icons";
import Lock from "@material-ui/icons/Lock";
import IndexContainer25 from "../../src/components/index-container-25";
import PartnerCard from "../../src/components/marketing/partner-card";

const useStyles = makeStyles(theme =>
    createStyles({
        link: {
            fontSize: 14
        },
        filledInput: {
            width: "100%",
            height: "46px",
            marginTop: theme.spacing(4)
        },
        spacer: {
            padding: theme.spacing(0, 2)
        },
        checkboxSpacer: {
            padding: theme.spacing(2, 4)
        },
        linkSpacer: {
            padding: theme.spacing(4, 2, 1, 2)
        }
    })
);

const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email("Bitte geben Sie eine gültige E-Mail Adresse ein")
        .lowercase("alle Zeichen sollten klein geschrieben werden")
        .required("Eintrag kann nicht leer gelassen werden"),
    password: yup.string().required("Eintrag kann nicht leer gelassen werden")
});

const errors = {
    CredentialsSignin: "Anmeldung fehlgeschlagen. Überprüfen Sie, ob die von Ihnen angegebenen Daten korrekt sind.",
    default: "Unable to sign in."
};

const SignInError = ({ error }) => {
    const errorMessage = error && (errors[error] ?? errors.default);
    return <div>{errorMessage}</div>;
};

export default function Login() {
    const { error } = useRouter().query;
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ defaultValues: { email: "", password: "", rememberMe: false }, resolver: yupResolver(loginSchema) });

    const handleSignin: SubmitHandler<{ email: string; password: string; rememberMe: boolean }> = async data => {
        await signIn("credentials", { ...data, callbackUrl: "/profile" });
    };

    const [positionStyle, setPositionStyle] = useState<"absolute" | "relative">("absolute");

    // NOTE: This is for fixing the button issue. We should rewrite the index container. Didn't do it now as it is used in multiple places and might break something
    const detectZoom = () => {
        const zoomLevel = window.outerWidth / window.innerWidth;
        if (zoomLevel > 1 || window.innerWidth < 1200) {
            setPositionStyle("relative");
        } else {
            setPositionStyle("absolute");
        }
    };

    useEffect(() => {
        detectZoom();
        window.addEventListener("resize", detectZoom);
        return () => window.removeEventListener("resize", detectZoom);
    }, []);

    return (
        <>
            <IndexContainer25>
                <Box justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"} style={{ width: "100%" }}>
                    <div className="wrapper" style={{ width: "100%", height: "100%", maxWidth: 800 }}>
                        <Grid container justifyContent="center" alignItems="flex-start">
                            <Grid container justifyContent="center" alignItems="center" item xs={12} md={6}>
                                <Card
                                    style={{
                                        minWidth: 380,
                                        maxWidth: 380,
                                        padding: 16
                                    }}
                                >
                                    <CardContent>
                                        <Grid container justifyContent={"center"}>
                                            <Typography style={{ fontWeight: 700, fontSize: 20, marginBottom: 4 }}>Login</Typography>
                                        </Grid>
                                        <Grid container justifyContent={"center"}>
                                            <Typography
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "17px",
                                                    color: "#8898AA"
                                                }}
                                            >
                                                Hey, willkommen zurück! 👋
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <form>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Controller
                                                        control={control}
                                                        name="email"
                                                        render={({ field }) => (
                                                            <FilledInput
                                                                {...field}
                                                                className={classes.filledInput}
                                                                placeholder="E-Mail"
                                                                autoComplete="off"
                                                                required
                                                                type="email"
                                                                error={!!errors.email}
                                                                startAdornment={
                                                                    <InputAdornment style={{ background: "transparent" }} position="start">
                                                                        <Email />
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Typography variant="inherit" color="error">
                                                        {errors.email?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <Controller
                                                        control={control}
                                                        name="password"
                                                        render={({ field }) => (
                                                            <FilledInput
                                                                {...field}
                                                                className={classes.filledInput}
                                                                placeholder="Passwort"
                                                                autoComplete="off"
                                                                required
                                                                type={showPassword ? "text" : "password"}
                                                                error={!!errors.password}
                                                                startAdornment={
                                                                    <InputAdornment style={{ background: "transparent" }} position="start">
                                                                        <Lock />
                                                                    </InputAdornment>
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end" style={{ background: "transparent" }}>
                                                                        <ButtonBase onClick={() => setShowPassword(!showPassword)}>
                                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                        </ButtonBase>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="inherit" color="error">
                                                        {errors.password?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.checkboxSpacer}>
                                            <Grid container justifyContent={"flex-start"}>
                                                <Controller
                                                    control={control}
                                                    name="rememberMe"
                                                    render={({ field: { value, onChange } }) => (
                                                        <FormControlLabel
                                                            control={<Checkbox color={"primary"} checked={value} onChange={onChange} />}
                                                            label={<Typography style={{ fontSize: 15 }}>Anmeldedaten speichern</Typography>}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </div>
                                        <div className={classes.spacer}>
                                            <Grid container justifyContent={"center"}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color={"primary"}
                                                    onClick={handleSubmit(handleSignin)}
                                                    isRedirect={false}
                                                    style={{ width: "90%", backgroundColor: "#0071E3" }}
                                                >
                                                    Anmelden
                                                </LoadingButton>
                                            </Grid>
                                        </div>
                                        <div className={classes.linkSpacer}>
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Link href="/signup/website">
                                                        <Typography className={classes.link}>Account erstellen</Typography>
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link href="/forgot-password">
                                                        <Typography className={classes.link}>Passwort vergessen?</Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Typography variant="inherit" align={"center"} color="error" style={{ fontSize: 14 }}>
                                            {error && <SignInError error={error} />}
                                        </Typography>
                                    </form>
                                </Card>
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center" item xs={12} md={6}>
                                <PartnerCard />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container item xs={12} style={{ width: "100%", maxWidth: 800 }}>
                        <RelativeFooter />
                    </Grid>
                </Box>
            </IndexContainer25>
        </>
    );
}
