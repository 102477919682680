import React, { FC, useEffect } from "react";
import { Box, useTheme } from "@material-ui/core";
import { IndexNavbar, StaticFooter } from "@onpreo/components";
import { doc } from "prettier";

interface IndexContainerProps {
    logo?: string;
    noFooter?: boolean;
}

const IndexContainer25: FC<IndexContainerProps> = props => {
    const theme = useTheme();

    useEffect(() => {
        if (typeof document !== "undefined") document.body.style.backgroundColor = "#fff";
    }, []);

    return (
        <Box>
            <IndexNavbar logo={{ imgSrc: props?.logo ?? "/logo.png", imgAlt: "onpreo" }} />
            <Box
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
                style={{ backgroundColor: "transparent", marginTop: theme.spacing(8) }}
            >
                {props.children}
            </Box>
            {/* {!props?.noFooter && <StaticFooter dark={true} />} */}
        </Box>
    );
};

export default IndexContainer25;
